/**
 * 请在对应的 ts 文件中修改，搭配 IDEA 自动编译，更好管理组件独立js逻辑,解决 ui.fronted 文件夹的依赖
 * 请在对应的 ts 文件修改后,编译成 js 文件，可参考 webpack , ts-loader
 * Please modify it in the corresponding ts file and compile it automatically with IDEA to better manage component independent js logic and solve the dependency of ui.fronted folder
 * Modify the ts file and compile it into a js file. For details, see webpack, ts-loader
 */

import { Swiper } from "swiper";
import { Pagination } from "swiper/modules";

/**
 * 请勿移除闭包 ，
 * 请勿将代码写在闭包外面
 * 为防止全局参数污染 ！！！
 */
(() => {
  const componentName: string = "cmp-fullscreen-multitab-new";

  const $components = document.getElementsByClassName(componentName) as HTMLCollectionOf<HTMLElement>;

  Array.prototype.forEach.call($components, ($component: HTMLElement) => {
    const $swiperContainer = $component.querySelector<HTMLElement>(".swiper");

    if ($swiperContainer !== null) {
      const $swiperPagination = $swiperContainer.querySelector<HTMLElement>(".swiper-pagination");
      console.log($swiperPagination, '$swiperPagination');
      const swiper = new Swiper($swiperContainer, {
        direction: "horizontal",
        modules: [Pagination],
        loop: true,
        pagination: {
          el: $swiperPagination,
          type: "bullets",
          enabled: true
        }
      });
      console.log(swiper);
    }
  });

  // 循环页面上的组件
  // $componentName.forEach(($component) => {
  //   const $swiperContainer = $component.querySelector<HTMLElement>(".swiper");
  //   console.log($component, '$swiperContainer');
  //   if ($swiperContainer !== null) {
  //     const swiper = new Swiper($swiperContainer, {
  //       direction: "horizontal"
  //     });
  //     console.log(swiper, 'swiper');
  //   }
  //   console.log($component, 'cc');
  // });
})();
