
var THREHOLD_RWD_MOBILE_LAYOUT = 541;

export function isIOS () {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        return true;
    } else {
        return navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 2 &&
            /MacIntel/.test(navigator.platform);
    }
}

export function isMobile() {
    return ($(window).innerWidth() < THREHOLD_RWD_MOBILE_LAYOUT) || isIOS() || /(android|bbd+|meego).+mobile|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent.toLowerCase());
}

export function isIE11() {
    return (document.documentMode) ? (document.documentMode === 11) : false;
}

export function isWechat (){
    if( navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== "undefined" ) {
        return true;
    }
    return false;
}

export function isEmpty(data) {
    if (!data) {
        return true;
    } else if (Array.isArray(data)) {
        return data.length === 0;
    } else if (typeof data === 'object') {
        return Object.keys(data).length === 0;
    }

    return false;
}

export function updateMobileHref() {
    if ($(".js-update-mobile-viewport").length) {
        if (isMobile()) {
            $(".js-update-mobile-viewport").each(function () {
                let mobile = $(this).data("mobile");
                if (mobile) {
                    $(this).attr("href", mobile);
                }
            })
        }
    }
}