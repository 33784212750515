import { isWechat } from "../../../../../../../../../../../ui.frontend/src/main/webpack/commons/utils";

(() => {
  const cardChangeable = document.querySelectorAll('.cmp-cardChangeable');
  Array.from(cardChangeable).forEach((item, index) => {
    // Cancel height after component loading
    setTimeout(() => {
      const section: HTMLElement | null = item?.closest('.section');
      const inlineStyle: string | undefined = section?.style.cssText;
      const horizontal = item.querySelector(".container");
      if (horizontal?.classList.contains("normalCardWaterFlow")) {
        section?.style.cssText = inlineStyle?.replace(/height\s*:\s*[^;]+;/i, '');
      }
    }, 1000);

    // init add-card and send Json
    const cardListContainer = item.querySelector('.card-list');
    const cardData = JSON.parse(
      cardListContainer?.getAttribute('data-my-array')
    );
    const cardLength = cardData.length;
    initCardList(0, 6);
    cardListContainer?.removeAttribute('data-my-array');
    const card = item.querySelector('.card');
    cardListContainer?.removeChild(card);

    //  init add-card
    let currentIndex: number;
    let cardDiv: HTMLElement;
    cardDiv = document.createElement('div');
    cardDiv?.classList.add('card');
    cardDiv?.classList.add('add-card');
    cardDiv?.classList.add('show');
    cardDiv.innerHTML = `<div class="cardSonDiv" >
                           <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" class="more-button" >
                                <path d="M24.5 18V31M31 24.5H18M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z"/>
                              </svg>
                              <p>加载更多</p>
                       </div>`;
    const container = item.querySelector('.container');
    const cardContainer = item.querySelector('.cardContainer');

    // init add-card hide/show
    setTimeout(() => {
      showRangeBox();
      currentIndex = Array.from(cardListContainer.children).filter(child => !child.classList.contains('add-card')).length;
      console.log("currentIndex", currentIndex);

      if (currentIndex === cardData.length) {
        loadMoreButton?.closest(".add-card").style.display = 'none';
      }
    }, 1000);

    // different display with different add-card
    if (container?.classList.contains("normalCardHorizontal")) {
      if (window.innerWidth < 428) {
        cardContainer?.appendChild(cardDiv);
      } else {
        cardListContainer?.appendChild(cardDiv);
      }
    } else {
      cardDiv?.classList.remove("card");
      cardContainer?.appendChild(cardDiv);
    }
    const loadMoreButton = item.querySelector('.more-button');

    // If the number of cards is less than the page width pull the bar to hide
    function showRangeBox (): void {
      setTimeout(() => {
        const cardContainerWidth = cardContainer?.clientWidth;
        const cardListWidth = cardListContainer?.clientWidth;
        if (cardContainerWidth < cardListWidth) {
          item.querySelector(".cardHandle")?.classList.add("show");
          item.querySelector(".cardHandle").style.display = 'block';
        }
      }, 500);
    }

    // Hide the loaded cards after all the cards are loaded
    function hideAddCard (): void {
      const cardLength: number = cardData.length;
      console.log("currentIndex", currentIndex);
      currentIndex = Array.from(cardListContainer?.children).filter(child => !child.classList.contains('add-card')).length;

      if (currentIndex === cardLength) {
        loadMoreButton?.closest(".add-card").style.display = 'none';
      }
    }

    // Core function
    function initCardList (currentIndex: number, newIndex: number): void {
      let cardDiv;
      for (let i = currentIndex; i < newIndex; i++) {
        if (i === cardLength) {
          break;
        }
        // create card
        cardDiv = document.createElement('div');
        cardDiv.classList.add('card');
        cardDiv.classList.add(cardData[i].cardTextBackgroundColour);
        cardDiv.setAttribute("index", i);
        const display = cardData[i].cardDisplay;
        cardDiv.classList.add(display);
        const html = document.querySelector("html");
        cardDiv.innerHTML = `<div class="card-textContent">
                               <div class="text-content">
                                   <div class="flex-content ${cardData[i].cardFullPageTextPosition}">
                                     <div class="titleAndDesc" >
                                       <div class="card-title pc-title ${cardData[i].cardTitle !== undefined ? '' : 'hidden'}">${cardData[i].cardTitle}</div>
                                       <div class="card-title mobileTitle ${cardData[i].cardTitleMobile !== undefined ? '' : 'hidden'}">${cardData[i].cardTitleMobile}</div>
                                        <div class="pc-desc card-desc ${cardData[i].cardSubTitle !== undefined ? '' : 'hidden'}">${cardData[i].cardSubTitle}</div>
                                        <div class="mobile-desc card-desc ${cardData[i].cardSubTitleMobile !== undefined ? '' : 'hidden'}">${cardData[i].cardSubTitleMobile}</div>
                                     </div>
                                     <div class="card-config ${cardData[i].cardButtonOneLink === undefined && cardData[i].enableDownload === 'False' && cardData[i].time === undefined ? 'hidden' : ''}  " >
                                         <div class="card-time ${cardData[i].time === undefined ? 'hidden' : ''}" >${cardData[i].time}</div>
                                          <div class="button-group ${cardData[i].cardButtonOneLink === undefined && cardData[i].enableDownload === 'False' ? 'hidden' : ''}">
                                             <a class="card-button ${cardData[i].cardButtonOneLink === undefined ? 'hidden' : ''}" href="${cardData[i].cardButtonOneLink}" target="${cardData[i].cardButtonOneTarget}"><button class="${cardData[i].cardButtonOneStyle}"">${cardData[i].cardButtonOneLabel === undefined ? '了解更多' : cardData[i].cardButtonOneLabel}</button></a>
                                             <div class="btnPop" style="position:relative;">
                                               <a class="card-download-button ${cardData[i].enableDownload === 'False' ? 'hidden' : ''}"><button class="${cardData[i].cardDownloadButtonStyle}"">下载壁纸</button></a>
                                               <div class="popUp" style="pointer-events: auto">
                                                  <div style=""><div class="arrow"></div></div>
                                                  <div class="imgsBox">
                                                      <a class="imgBox ${cardData[i].DownloadSmallLabel === undefined ? 'hidden' : ''}" download="${cardData[i].cardContentType === 'Video' ? cardData[i].DownloadSmallLabel + '.mp4' : cardData[i].DownloadSmallLabel + '.jpg'}" href="${cardData[i].DownloadSmallLink}">${cardData[i].DownloadSmallLabel}</a>
                                                      <a class="imgBox ${cardData[i].DownloadMiddleLabel === undefined ? 'hidden' : ''}" download="${cardData[i].cardContentType === 'Video' ? cardData[i].DownloadMiddleLabel + '.mp4' : cardData[i].DownloadMiddleLabel + '.jpg'}" href="${cardData[i].DownloadMiddleLink}">${cardData[i].DownloadMiddleLabel}</a>
                                                      <a class="imgBox ${cardData[i].DownloadBigLabel === undefined ? 'hidden' : ''}" style="border-bottom: none" download="${cardData[i].cardContentType === 'Video' ? cardData[i].DownloadBigLabel + '.mp4' : cardData[i].DownloadBigLabel + '.jpg'}" href="${cardData[i].DownloadBigLink}">${cardData[i].DownloadBigLabel}</a>
                                                  </div>
                                              </div>
                                            </div>
                                   </div>
                                     </div>
                                   </div>
                               </div>
                           </div>
                            <div class="card-content" data-type="${cardData[i].cardContentType}">
                               <div class="img-content">
                                    <picture>
                                       <img src="${cardData[i].cardImage}" alt=""/>
                                   </picture>
                                    <div class="play-icon ${cardData[i].cardContentType === 'Video' ? '' : 'hidden'}">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                 <path fill-rule="evenodd" clip-rule="evenodd" d="M20 16.9L30.4 23.9L20 31.1V16.9ZM24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" stroke="white"/>
                                            </svg>
                                    </div>
                                    <video class="defer video-player embeded-video" type="video/mp4" playsinline="" controlslist="nodownload" loop muted="true"  isautoplay = ${cardData[i].autoplay} poster="${cardData[i].cardVideoPoster}" style="width: 100%;height: 100%;object-fit: cover" x5-video-player-fullscreen="true" x5-playsinline
                         webkit-playsinline>
                                        <source class="defer" src="${cardData[i].cardVideo}"  type="video/mp4" />
                                    </video>
                               </div>
                               <div class="iconContainer">
                                 <div class="amplify">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M15 2.5H21.4919V9M13.5 10.5L21.5 2.5M10.5 13.5L2.5 21.5M2.5 21.5V15M2.5 21.5H8.9919" stroke="white"/>
                                      </svg>
                                  </div>
                                <div class="downloadIconContainer">
                                  <div class="downloadIcon">
                                     <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M8 25.9987H25.3333M22.6667 14.6654L16.6667 20.6654M16.6667 20.6654L10.6667 14.6654M16.6667 20.6654C16.6667 20.6654 16.6667 15.5543 16.6667 5.33203" stroke="white"/>
                                     </svg>
                                  </div>
                                   <div class="popUp" style="pointer-events: auto">
                                                  <div style=""><div class="arrow"></div></div>
                                                  <div class="imgsBox">
                                                      <a class="imgBox ${cardData[i].DownloadSmallLabel === undefined ? 'hidden' : ''}" download="${cardData[i].cardContentType === 'Video' ? cardData[i].DownloadSmallLabel + '.mp4' : cardData[i].DownloadSmallLabel + '.jpg'}" href="${cardData[i].DownloadSmallLink}">${cardData[i].DownloadSmallLabel}</a>
                                                      <a class="imgBox ${cardData[i].DownloadMiddleLabel === undefined ? 'hidden' : ''}" download="${cardData[i].cardContentType === 'Video' ? cardData[i].DownloadMiddleLabel + '.mp4' : cardData[i].DownloadMiddleLabel + '.jpg'}" href="${cardData[i].DownloadMiddleLink}">${cardData[i].DownloadMiddleLabel}</a>
                                                      <a class="imgBox ${cardData[i].DownloadBigLabel === undefined ? 'hidden' : ''}" style="border-bottom: none" download="${cardData[i].cardContentType === 'Video' ? cardData[i].DownloadBigLabel + '.mp4' : cardData[i].DownloadBigLabel + '.jpg'}" href="${cardData[i].DownloadBigLink}">${cardData[i].DownloadBigLabel}</a>
                                                  </div>
                                              </div>
                               </div>
                                </div>
                            </div>`;
        cardListContainer?.appendChild(cardDiv);

        // If it is mobile terminal or wechat terminal, click the play button to play,cancel autoplay.
        if (window.innerWidth <= 1024 ?? isWechat()) {
          const playIcon = cardDiv.querySelector('.play-icon');
          const videos = cardDiv.querySelectorAll('video');
          let currentVideo;
          Array.from(videos).forEach(video => {
            if (!video.classList.contains('hidden')) {
              currentVideo = video;
            }
          });
          currentVideo.removeAttribute("isautoplay");
          playIcon?.addEventListener('touchstart', () => {
            void currentVideo?.play();
            playIcon.classList.add('exit');
          });
        } else {
          cardDiv.querySelector('.play-icon').classList.add('hidden');
        }

        // pctitle is displayed on the PC and mobiletitle is displayed on the mobile,if mobileTitle is null,pctitle will be displayed on pc terminal
        const pcTitleElements = cardDiv.querySelector(".pc-title");
        const mobileTitleElements = cardDiv.querySelector(".mobileTitle");
        const pcDescElements = cardDiv.querySelector(".pc-desc");
        const mobileDescElements = cardDiv.querySelector(".mobile-desc");
        if (!html?.classList.contains("mobile")) {
          mobileTitleElements.classList.add('hidden');
          mobileDescElements.classList.add('hidden');
        } else {
          if (cardData[i].cardTitleMobile === undefined) {
            mobileTitleElements.classList.add('hidden');
          } else {
            pcTitleElements.classList.add('hidden');
          }

          if (cardData[i].cardSubTitleMobile === undefined) {
            mobileDescElements.classList.add('hidden');
          } else {
            pcDescElements.classList.add('hidden');
          }
        }
      }

      const allCards = item.querySelectorAll('.card');
      Array.from(allCards).forEach((item) => {
        //  card animation
        item.classList.add('show');
        const index = item.getAttribute("index");

        // show the selected choice in the card-content
        const contentType = item.querySelector(".card-content");
        let noneItem;
        let showItem;
        if (item.classList.contains("add-card")) {
          return false;
        } else {
          if (contentType?.getAttribute("data-type") === 'Image') {
            noneItem = contentType.querySelector("video");
            showItem = contentType.querySelector("picture");
            noneItem?.style.display = 'none';
            showItem?.style.display = 'block';
          } else {
            noneItem = contentType.querySelector("picture");
            showItem = contentType.querySelector("video");
            noneItem?.style.display = 'none';
            showItem?.style.display = 'block !important';
          }
        }

        const amplify = item?.querySelector(".amplify");
        const popUpWindow = item.closest(".cmp-cardChangeable ")?.querySelector(".popUpField");
        const img = popUpWindow?.querySelector("img");
        const popUpVideo = popUpWindow?.querySelector("video");
        const closeBtn = popUpWindow?.querySelector(".close-btn");
        const popUpBtn = popUpWindow?.querySelector(".card-download-button");
        const popUpDownload = popUpWindow?.querySelector(".popUp");
        const imgsBox = popUpWindow?.querySelectorAll(".imgBox");
        let timer;
        const downloadButton = item.querySelector(".downloadIconContainer")?.querySelector(".downloadIcon");
        const buttonGroup = item.querySelector(".downloadIconContainer");

        console.log("downloadButton", downloadButton);
        console.log("buttonGroup", buttonGroup);
        // click the amplify button ,popWindow will show out the card-content
        amplify?.addEventListener("click", () => {
          if (cardData[index].enableDownload === "False") {
            popUpBtn?.classList.add("hidden");
          } else {
            const button = popUpBtn?.querySelector("button");
            // Custom text
            const downloadText = button.querySelector("span:nth-child(2)");
            if (cardData[index].cardDownloadButtonLabel !== undefined) {
              downloadText.innerHTML = cardData[index].cardDownloadButtonLabel;
            } else {
              downloadText.innerHTML = '下载壁纸';
            }

            popUpBtn?.classList.remove("hidden");
          }
          if (popUpWindow.style.display === 'none') {
            popUpWindow.style.display = 'block';
            document.body.style.overflow = 'hidden';
            if (cardData[index].cardContentType === 'Image') {
              img.style.display = 'block';
              popUpVideo.style.display = 'none';
              img.setAttribute("src", cardData[index].cardImage);
              // popUpBtn.classList.add(cardData[index].cardDownloadButtonStyle);
              popUpBtn.setAttribute("data-sly-test", cardData[index].enableDownload);
              Array.from(imgsBox)[0].innerHTML = cardData[index].DownloadSmallLabel;
              Array.from(imgsBox)[0].setAttribute("href", cardData[index].DownloadSmallLink);
              Array.from(imgsBox)[0].setAttribute("download", cardData[index].DownloadSmallLabel + '.jpg');
              Array.from(imgsBox)[1].innerHTML = cardData[index].DownloadMiddleLabel;
              Array.from(imgsBox)[1].setAttribute("href", cardData[index].DownloadMiddleLink);
              Array.from(imgsBox)[1].setAttribute("download", cardData[index].DownloadMiddleLabel + '.jpg');
              Array.from(imgsBox)[2].innerHTML = cardData[index].DownloadBigLabel;
              Array.from(imgsBox)[2].setAttribute("href", cardData[index].DownloadBigLink);
              Array.from(imgsBox)[2].setAttribute("download", cardData[index].DownloadBigLabel + '.jpg');
            } else {
              img.style.display = 'none';
              popUpVideo.style.display = 'block';
              popUpVideo?.setAttribute("src", cardData[index].cardVideo);
              popUpBtn.setAttribute("data-sly-test", cardData[index].enableDownload);
              Array.from(imgsBox)[0].innerHTML = cardData[index].DownloadSmallLabel;
              Array.from(imgsBox)[0].setAttribute("href", cardData[index].DownloadSmallLink);
              Array.from(imgsBox)[0].setAttribute("download", cardData[index].DownloadSmallLabel + '.mp4');
              Array.from(imgsBox)[1].innerHTML = cardData[index].DownloadMiddleLabel;
              Array.from(imgsBox)[1].setAttribute("href", cardData[index].DownloadMiddleLink);
              Array.from(imgsBox)[1].setAttribute("download", cardData[index].DownloadMiddleLabel + '.mp4');
              Array.from(imgsBox)[2].innerHTML = cardData[index].DownloadBigLabel;
              Array.from(imgsBox)[2].setAttribute("href", cardData[index].DownloadBigLink);
              Array.from(imgsBox)[2].setAttribute("download", cardData[index].DownloadBigLabel + '.mp4');
            }
            let hiddenCount = 0;
            Array.from(imgsBox).forEach((btn, index) => {
              if (btn.innerHTML === "undefined") {
                btn.classList.add("hidden");
                hiddenCount += 1;
                if (hiddenCount === 3) {
                  popUpDownload?.querySelector(".arrow")?.classList.add('hidden');
                } else {
                  popUpDownload?.querySelector(".arrow")?.classList.remove('hidden');
                }
              } else {
                btn.classList.remove("hidden");
              }
            });
          }
        });
        // popUpWindow button
        downloadButton?.addEventListener("mouseover", () => {
          const popUp = buttonGroup?.querySelector(".popUp");
          popUp.style.display = 'flex';
          const imgsBox = popUp?.querySelectorAll(".imgBox");
          let hiddenCount = 0;
          Array.from(imgsBox).forEach((imgBox, index) => {
            if (imgBox.classList.contains("hidden")) {
              hiddenCount += 1;
              if (hiddenCount === 3) {
                popUp?.querySelector(".arrow")?.classList.add('hidden');
              } else {
                popUp?.querySelector(".arrow")?.classList.remove('hidden');
              }
            }
          });
        });
        downloadButton?.addEventListener("touchstart", (event) => {
          const popUp = buttonGroup?.querySelector(".popUp");
          console.log(event.target);
          if (popUp.style.display === 'flex') {
            popUp.style.display = 'none';
          } else {
            popUp.style.display = 'flex';
          }
        });
        const popUp = buttonGroup?.querySelector(".popUp");
        popUp?.addEventListener("mouseleave", () => {
          popUp.style.display = 'none';
        });
        downloadButton?.addEventListener("mouseleave", () => {
          timer = setTimeout(() => {
            popUp.style.display = 'none';
          }, 500);
        });
        popUp?.addEventListener("mouseenter", () => {
          clearTimeout(timer);
        });

        // closeButton handler
        closeBtn?.addEventListener("click", () => {
          if (popUpWindow.style.display === 'block') {
            document.body.style.overflow = 'auto';
            popUpWindow.style.display = 'none';
            popUpVideo.pause();
          }
        });

        // card download button handler
        popUpBtn?.addEventListener("mouseover", () => {
          popUpDownload.style.display = 'block';
        });
        popUpBtn?.addEventListener("touchstart", () => {
          if (popUpDownload.style.display === 'block') {
            popUpDownload.style.display = 'none';
          } else {
            popUpDownload.style.display = 'block';
          }
        });
        popUpBtn?.addEventListener("mouseleave", () => {
          timer = setTimeout(() => {
            popUpDownload.style.display = 'none';
          }, 200);
        });
        popUpDownload.addEventListener("mouseover", () => {
          clearTimeout(timer);
          popUpDownload.style.display = 'block';
        });
        popUpDownload.addEventListener("mouseleave", () => {
          popUpDownload.style.display = 'none';
        });

        // Video appearance mode
        const video = item.querySelector("video");
        if (video?.getAttribute("isAutoplay") === "hoverAutoplay") {
          // 获取屏幕宽度，如果屏幕宽度为768px以下添加播放按钮点击播放
          video?.addEventListener("mouseover", () => {
            video.play();
          });
          video?.addEventListener("mouseleave", () => {
            video?.pause();
          });
        } else if (video?.getAttribute("isAutoplay") === "loadedAutoplay") {
          video?.autoplay = true;
        }
      });
    }

    // loading new card
    loadMoreButton?.addEventListener('click', () => {
      // if (container?.classList.contains("normalCardHorizontal")) {
      //   currentIndex += 1;
      // }
      const newIndex = currentIndex + 6;
      initCardList(currentIndex, newIndex);
      console.log("currentIndex", currentIndex);
      hideAddCard();
    });

    // Horizontal progress bar
    const cardHandle = item.querySelector(".cardHandle");
    cardHandle.value = 0;
    cardHandle?.addEventListener("input", (event) => {
      const percent_value = event.target?.value / 100;
      const width = cardListContainer.offsetWidth - cardContainer.offsetWidth;
      const value = width * percent_value;
      cardContainer?.scrollLeft = value;
    });
    if (container?.classList.contains("normalCardHorizontal")) {
      let imgContent: NodeListOf<Element>;
      let dragging = false;
      let startX = 0;
      let startScrollLeft = 0;
      let offsetX = 0;
      let newScrollLeft = 0;
      let maxX = 0;
      let clampedScrollLeft = 0;
      let percentage = 0;

      cardContainer?.addEventListener("mousedown", (event) => {
        imgContent = item.querySelectorAll(".img-content");
        dragging = true;
        startX = event?.clientX;
        startScrollLeft = cardContainer?.scrollLeft;
      });

      cardContainer?.addEventListener("mousemove", (event) => {
        if (!dragging) return false;
        offsetX = event.clientX - startX;
        newScrollLeft = startScrollLeft - offsetX;
        maxX = cardContainer?.children[0].clientWidth - cardContainer?.clientWidth;
        clampedScrollLeft = Math.min(Math.max(0, newScrollLeft), maxX);
        cardContainer?.scrollLeft = clampedScrollLeft;
        percentage = (clampedScrollLeft / maxX) * 100;
        cardHandle.value = percentage;

        Array.from(imgContent).forEach((item) => {
          item.classList.add("noPointer");
        });
      });

      cardContainer?.addEventListener("mouseup", (event) => {
        dragging = false;
        Array.from(imgContent).forEach((item) => {
          item.classList.remove("noPointer");
        });
      });

      cardContainer?.addEventListener("touchstart", (event) => {
        dragging = true;
        startX = event?.touches[0].clientX;
        startScrollLeft = cardContainer?.scrollLeft;
      });

      cardContainer?.addEventListener("touchmove", (event) => {
        if (!dragging) return false;
        offsetX = event?.touches[0].clientX - startX;
        newScrollLeft = startScrollLeft - offsetX;
        maxX = cardContainer?.children[0].clientWidth - cardContainer?.clientWidth;
        clampedScrollLeft = Math.min(Math.max(0, newScrollLeft), maxX);
        cardContainer?.scrollLeft = clampedScrollLeft;
        percentage = (clampedScrollLeft / maxX) * 100;
        cardHandle.value = percentage;
      });

      cardContainer?.addEventListener("touchend", (event) => {
        dragging = false;
      });
    }
  });
})();
